<script lang="tsx">
import { defineComponentCoreUiCounter } from '@core/app/components/core/ui/CoreUiCounter.vue'
import { IconMinus, IconPlus } from '#components'

export default defineComponentCoreUiCounter({
    slots: {
        minusIcon: () => <IconMinus size="xs" />,
        plusIcon: () => <IconPlus size="md" />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiCounter" as *;

@include counter {
    padding: 0.625rem;
    border-radius: 0.75rem;
    color: $sim-c-primary-900;

    @include more-than(lg) {
        padding: 0.8rem 0.625rem;
    }
}

@include input {
    @include sim-text-large;
    @include sim-font-medium;
    color: $sim-c-primary-900;
}

@include button {
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 100%;
    background-color: $sim-c-basic-100;
    transition: background-color $sim-trans-time-normal $sim-timing, color $sim-trans-time-normal $sim-timing;
}

@include button-hover {
    background-color: $sim-c-primary-900;
    color: $sim-c-white;
}
</style>
