<script lang="tsx">
import type { ComponentOverrideOptions } from '@core-types/components'
import type { DefineComponent, PropType, SlotsType } from 'vue'

export type CoreUiCounterProps<T> = {
    modelValue?: number,
    minValue?: number | undefined | null
    maxValue?: number | undefined | null
    disabled?: boolean
    counterClass?: string
    onInput?: (value: number) => boolean | void
}

type CoreUiCounterSlots<T> = {
    default: {}
    minusIcon: {}
    plusIcon: {}
}

export function defineComponentCoreUiCounter<T>(_options?: ComponentOverrideOptions<{}, {}, CoreUiCounterSlots<T>>): DefineComponent<CoreUiCounterProps<T>> {
    return defineComponent({
        name: 'CoreUiCounter',
        props: {
            value: {
                type: Number as PropType<CoreUiCounterProps<T>['modelValue']>,
                default: 1,
                required: false,
            },
            minValue: {
                type: Number as PropType<CoreUiCounterProps<T>['minValue']>,
                default: 1,
                required: false,
            },
            maxValue: {
                type: Number as PropType<CoreUiCounterProps<T>['maxValue']>,
                default: undefined,
                required: false,
            },
            disabled: {
                type: Boolean as PropType<CoreUiCounterProps<T>['disabled']>,
                default: false,
                required: false,
            },
            counterClass: {
                type: String as PropType<CoreUiCounterProps<T>['counterClass']>,
                default: undefined,
                required: false,
            },
            onInput: {
                type: Function as PropType<CoreUiCounterProps<T>['onInput']>,
                default: undefined,
                required: false,
            },
        },
        slots: Object as SlotsType<CoreUiCounterSlots<T>>,
        emits: {
            'update:modelValue': (value: number) => true,
            'update': (value: number) => true,
        },
        setup: (props, {
            slots,
            emit,
        }) => {
            const counter = ref<number>(props.value!)
            const min = ref<number>(props.minValue ?? 1)
            const max = ref<number | undefined | null>(props.maxValue)
            const { t } = useI18n()

            const isDecrementDisabled = computed<boolean>(() => {
                return counter.value - 1 < min.value
            })

            const isIncrementDisabled = computed<boolean>(() => {
                return !!max.value && counter.value + 1 > max.value
            })

            const setValue = (newValue: number) => {
                if (props.onInput && props.onInput(newValue) === false) {
                    return
                }
                counter.value = newValue
                emit('update:modelValue', counter.value)
                emit('update', counter.value)
            }

            const increment = () => {
                if (props.disabled || isIncrementDisabled.value) return
                setValue(counter.value + 1)
            }

            const decrement = () => {
                if (props.disabled || isDecrementDisabled.value) return
                setValue(counter.value - 1)
            }

            const handleInput = (event: InputEvent) => {
                if (props.disabled) return
                const inputValue = (event.target as HTMLInputElement).value
                const numericValue = Number(inputValue)

                let newValue = numericValue
                if (numericValue < min.value) {
                    newValue = min.value
                } else if (max.value && numericValue > max.value!) {
                    newValue = max.value!
                }

                setValue(newValue)
            }

            watch(() => props.maxValue, () => {
                if (props.maxValue === undefined || props.maxValue === null) return

                max.value = props.maxValue
                if (counter.value > props.maxValue) {
                    counter.value = props.maxValue
                    emit('update:modelValue', counter.value)
                    emit('update', counter.value)
                }
            })

            return () => (
                <div class={['sim-counter', { 'sim-counter--disabled': props.disabled }, props.counterClass]}>
                    <button
                        class="sim-counter__button"
                        type="button"
                        tabindex="-1"
                        aria-label={t('accessibility.decrement_value')}
                        onClick={decrement}
                        disabled={props.disabled || isDecrementDisabled.value}
                    >
                        {
                            renderSlot(slots.minusIcon, _options?.slots?.minusIcon, {}, (<span>-</span>))
                        }
                    </button>

                    <input
                        v-model={counter.value}
                        class="sim-counter__input input-appearance-none"
                        type="number"
                        aria-label={t('accessibility.current_value')}
                        disabled={props.disabled}
                        onBlur={(event: Event) => handleInput(event as InputEvent)}
                    />

                    <button
                        type="button"
                        class="sim-counter__button"
                        tabindex="-1"
                        aria-label={t('accessibility.increment_value')}
                        onClick={increment}
                        disabled={props.disabled || isIncrementDisabled.value}
                    >
                        {
                            renderSlot(slots.plusIcon, _options?.slots?.plusIcon, {}, (<span>+</span>))
                        }
                    </button>
                </div>
            )
        },
    }) as unknown as DefineComponent<CoreUiCounterProps<T>>
}

export default defineComponentCoreUiCounter()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/CoreUiCounter" as *;
</style>
